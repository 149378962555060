.project-card {
	background-color: #01152e !important;
	box-shadow: 0 0 20px 0 rgba(100, 100, 100, 0.15);
	font-size: 1rem;
	height: 100%;
	text-align: left;
	width: 100%;
	cursor: pointer;
	&:hover{
		.image-container .image {
			opacity: 0.3;
		}
		.image-container .middle {
			opacity: 1;
		}	
	}
	.card-header {
		background-color: #212933;
		font-size: 0.9rem;
		.icon-section {
			padding: 0 0 0 12px;
			.circle-icon {
				border-radius: 50%;
				color: #222;
				display: inline-block;
				font-size: 10px;
				height: 0;
				width: 15%;
				padding-bottom: 15%;
				@media screen and (min-width: 900px) {
					width: 8%;
					padding-bottom: 8%;
				}
				margin-right: 7px;
				position: relative;
				.web-icon {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}
			.bg-red {
				background-color: #ff605c;
			}
			.bg-yellow {
				background-color: #ffbd44;
			}
			.bg-green {
				background-color: #00ca4e;
			}
		}

		.project-name {
			padding: 0;
		}
	}
	.image-container {
		position: relative;
		width: 100%;
		.image {
			opacity: 1;
			display: block;
			width: 100%;
			height: auto;
			transition: 0.5s ease;
			backface-visibility: hidden;
		}
		.middle {
			transition: 0.5s ease;
			opacity: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			text-align: center;
			width: 80%;
		}
	}
}
