html, .page{
    background-color: #01152e;
	margin: 0;
	color: #e1f2f7;

	.link-button {
		background-color: white;
		color: #000000;			
		&:hover {
			background-color: #484848;
			color: white;
		}
	}
}

.content-container {
	margin: 0 auto; /* Center the content */
	@media screen and (min-width: 900px) {
		padding: 0 20px;
	}
}

.footer-container, .content-container {
	width: 95%;
	max-width: 1400px; /* Adjust this value as needed */
	@media screen and (min-width: 900px) {
		width: 80%;
	}
}

.skill-item {
	background-color: #212933;
	color: white;
	display: inline-block;
	font-size: 1rem;
	margin: 6px;
	padding: 4px 12px;
	&.no-left-margin {
		margin-left: 0;
	}
}