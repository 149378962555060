@import "FluidType";

.header {
	.background-video {
		object-fit: cover;
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
	}

	.viewport-header {
		position: relative;
		height: 100vh;
		z-index: 2;
		padding: 20px;
		.content {
			color: #e1f2f7;
			.greeting {
				@include fluid-type(font-size, 900px, 1200px, 3em, 5em);
				margin-bottom: 40px;
			}
			.description {
				@include fluid-type(font-size, 900px, 1200px, 1.5em, 1.7em);
			}
		}
	}
}

