.experience-card {
	background-color: #00060dcc;
	border-radius: 0px 6px 6px 6px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
	font-size: 16px;
	line-height: 1.7;
	margin-bottom: 40px;
	padding: 20px 25px;
	position: relative;
	.left {
		height: 100%;
		text-align: center;
		.role {
			font-size: 1.5rem;
		}
		.company-img {
			border-radius: 7%;
			margin-bottom: 12px;
			width: 45%;
		}
		.company-name {
			font-size: 1.1rem;
		}
	}
	.right {
		@media screen and (min-width: 900px) {
			border-left: 2px solid #333;
		}
		.duration {
			color: #c0d0d5aa;
			font-size: 0.9rem;
			.icon {
				margin-right: 5px;
			}
		}
		.description {
			font-size: 1rem;
		}
		
	}
}
.experience-card:before {
	background-color: #000;
	border: 4px solid #cacbd9ff;
	border-radius: 50%;
	content: "";
	display: block;
	height: 20px;
	left: -81px;
	position: absolute;
	top: 10px;
	width: 20px;
}
.experience-card:after {
	border-style: solid;
	border-color: transparent #00060dcc transparent transparent;
	border-width: 15px;
	content: "";
	height: 0;
	left: -29px;
	position: absolute;
	top: 10px;
	width: 0;
}
