.footer-container {
    margin: 1rem auto 1rem auto;
    padding: 0 2rem;
    @media screen and (min-width: 900px) {
        margin: 4rem auto 1rem auto;
    }
    .footer-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        
    }

    .scroll-to-top-button {
        background-color: rgb(10, 10, 10);
        color: #fff;
        border: none;
        border-radius: 10%;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        font-size: 1.2rem;
        &:hover {
            background-color: rgb(10, 10, 10, 0.3);
            color: #AAA;
        }
      }
}