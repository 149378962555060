.contact {
	text-align: justify;
	margin: 0 20px;
	@media screen and (min-width: 900px) {
		margin: 0;
	}
	.title {
		display: flex;
		align-items: center;
		padding: 0;
		font-size: 2rem;
		margin-bottom: 2rem;
		@media screen and (min-width: 900px) {
			font-size: 2.5rem;
		}
	}

	.title::after {
		background-color: #e1f2f7dd;
		content: "";
		flex: 1;
		height: 1px;
		margin-left: 1rem;
		background-color: #e1f2f7dd;
	}
	.situation {
		background-color: #00060d;
		border-radius: 10px;
		font-size: 1.5rem;
		margin: auto;
		padding: 20px 40px;
		text-align: left;
	}
	.links-section {
		padding: 20px 0;
		display: flex;
        flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
        gap: 2rem; /* Adds space between items */
        justify-content: space-around;

        @media screen and (min-width: 900px) {
            justify-content: flex-start;
        }
		.link {
			color: #e1f2f7;
			font-size: 4rem;
			text-decoration: none;
		}
		.link:hover {
			color: #e1f2f722;
			transform: translateY(-20px);
		}
	}

	.location,
	.email {
		font-size: 1.3rem;
		padding: 4px;
	}

	.download-resume-section {
		text-align: center;
		margin: 2rem 0;
		@media screen and (min-width: 900px) {
            margin: 4rem 0;
        }
		.download-resume-link {
			text-align: center;
			text-decoration: none;
			background-color: #0002;
			border: 2px solid #023059;
			border-radius: 15px;
			color: #e1f2f7aa;
			font-size: 1.3rem;
			padding: 20px 40px;
		}

		.download-resume-link:hover {
			background-color: #4442;
			color: #e1f2f733;
		}
	}
}
