.home {
	.transition {
		z-index: -2;
		width: 100%;
		height: 200px;
		background: linear-gradient(to top left, #00060d 50%, transparent 50%),
			linear-gradient(to top right, #00060d 50%, transparent 50%), #01152e;
		margin-top: 20px;
	}
	.rotate-180 {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}