.project-page {
    
    .content-container{
        font-size: 1.1rem;
        padding: 0.5rem;
        @media screen and (min-width: 900px) {
            padding: 0 2rem;
        }
    }

    h1 {
        font-size: 3rem;
        margin: 1rem 0 0 0;
    }

    img {
        width: 100%;
        height: auto;
    }
    .video-demo-container{
        margin-top: 2rem;
    }
    .video-demo {
        width: 100%; /* 50% width on larger screens */
        aspect-ratio: 16 / 9;
        max-width: 100%; /* Prevent overflow */
        border: none;
        @media screen and (min-width: 900px) {
            width: 50%
        }
      }
    .project-subheading{
        font-size: 1.05rem;
        span {
            .icon {
				margin-right: 0.25rem;
			}
            margin-right: 2rem;
        }
    }

    .bullet-points-list {
        li {
            margin: 0.5rem 0;
        }
    }

    .skills-title{
        text-align: center;
        margin: 1rem 0;
        font-size: 1.5rem;
        @media screen and (min-width: 900px) {
            text-align: left;
        }
    }
    .skills{
        display: flex;
        flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
        gap: 10px; /* Adds space between items */
        justify-content: center;

        @media screen and (min-width: 900px) {
            justify-content: flex-start;
        }
    }

    .links {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
        gap: 10px; /* Adds space between items */
        justify-content: center;

        @media screen and (min-width: 900px) {
            justify-content: flex-start;
        }
    }

    .project-description {
        margin: 1rem 0;
    }

}