@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
	@each $property in $properties {
		#{$property}: $min-value;
	}

	@media screen and (min-width: $min-vw) {
		@each $property in $properties {
			#{$property}: calc(
				#{$min-value} +
					#{strip-unit($max-value - $min-value)} *
					(100vw - #{$min-vw}) /
					#{strip-unit($max-vw - $min-vw)}
			);
		}
	}

	@media screen and (min-width: $max-vw) {
		@each $property in $properties {
			#{$property}: $max-value;
		}
	}
}
