.experience {
	
	.title {
		display: flex;
		align-items: center;
		padding: 0;
		font-size: 2rem;
		margin-bottom: 2rem;
		@media screen and (min-width: 900px) {
			font-size: 3.5rem;
		}
	}

	.title::before {
		background-color: #e1f2f7dd;
		content: "";
		flex: 1;
		height: 1px;
		margin-right: 1rem;
		background-color: #e1f2f7dd;
	}
	.timeline-area {
		border-left: none;
		padding: 0;
		margin: 0 0.5rem;
		@media screen and (min-width: 900px) {
			margin-top: 3rem;
			margin: 0 3rem;
			border-left: 2px solid #cacbd9ff;
			padding: 0 0 0 70px;
		}
	}
}
